@import "colors";

.home {
  color: white;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../assets/home_bg.png');
  background-color: transparent;
  // background-size: cover;

  .azLogo {
    position: absolute;
    top: 25px;
    left: 50px;
    width: 220px;

  }

  .navBoxes {
    display: flex;
    justify-content: 'center';
    align-items: 'center';
  }

  .navBox {
    color: 'white';
    margin: 15px;
    width: 480px;
    height: 435px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      transform: scale(1.05);
    }
  }

  .navBoxGame {
    background-image: url('../assets/home_game_button.png');

  }
  .navBoxOne {
    background-image: url('../assets/home_one_button.png');
  }

  @media screen and (max-width: 1050px) {

    padding-top: 80px;


    .azLogo {
      left: unset;
      margin: 0 auto;
    }

    .navBoxes {
      flex-direction: column;
    }
    .navBox {
      height: 280px;
      // border: 1px solid blue;
    }
  }

}