$darkblue: #023967;
$lightblue: #5ec2d2;
$midgray: #838788;
$midLightGray: #c5c3c1;
$darkgray: #4a4a4a;
$lightgray: #fcfcfc;
$otherGray: #3f4444;
$purple: #811953;
$red: #d24770;
$yellow: #f0a529;
$green: #c3d532;
$white: #ffffff;

:export {
  darkblue: $darkblue;
  midgray: $midgray;
  midLightGray: $midLightGray;
  darkgray: $darkgray;
  lightgray: $lightgray;
  otherGray: $otherGray;
  purple: $purple;
  yellow: $yellow;
  green: $green;
  white: $white;
  red: $red;
  lightblue: $lightblue;
}
