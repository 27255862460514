// $blue: #111c4e;
// $lightblue: #c8f1f9;
// $verylightblue: #e2f8fc;
// $green: #9dbf2a;
// $darkgrey: #3f3f3f;
// $white: #ffffff;
// $pink: #bb8dbf;

@import 'colors';

* {
  box-sizing: border-box;
  outline: none;
  font-family: 'Helvetica Neue', Helvetica;
}

html,
body {
  min-height: 100%;
  width: 100%;
  margin: auto;
  overflow: auto;
}

body {
  margin: 0;
  // font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;

  .direction-column {
    flex-direction: column;
  }
}

.flex-center {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.col {
  flex: 1;
  min-width: 50%;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

ol li {
  margin-bottom: 0.5rem;
}

ol li,
.modal-text {
  font-size: 1.2rem;
}

.modal-header-text {
  font-size: 1.5rem;
}

.game-sup {
  position: relative;
  top: -0.08em;
  vertical-align: top;
  font-size: 0.8rem;
}

.game-sup2 {
  position: relative;
  top: -0.08em;
  vertical-align: top;
  font-size: -0.2rem;
}

.center-text {
  text-align: center;
}

.bold {
  font-weight: bold !important;
}

.light {
  color: white;
}
.dark-gray-text {
  color: $darkgray;
}

.gray-text {
  color: $midgray;
}

.purple {
  color: $purple;
}

.yellow {
  color: $yellow;
}

.darkBlue {
  color: $darkblue;
  background-color: white;
}

.numericKeyBoardNV {
  background-color: transparent !important;
  color: $darkblue;
  display: flex;
  justify-content: center;
  font-size: 42px;
  font-weight: bold;

  .hg-rows {
    background-color: transparent !important;

    .hg-row {
      margin-bottom: 12px !important;
      width: 380px;

      .hg-button {
        width: 60px !important;
        height: 90px !important;
        margin-right: 12px !important;
      }
      .nv-delete {
        font-size: 36px;
        width: 186px !important;
      }
      .nv-submit-lightblue {
        background-color: $lightblue !important;
        font-size: 36px;
        // width: 216px !important;
      }
    }
  }
}

.numericKeyBoardNVDark {
  color: white;
  .hg-button {
    background-color: $darkblue !important;
  }
  .nv-submit-lightblue {
    color: $darkblue !important;
  }
}

.input-zipcode {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.input-zipcode div:before {
  border-bottom: none !important;
}

.select-interest-topic {
  font-size: 2rem !important;
}

.live-leaderboard-wrapper {
  width: 125% !important;
  max-width: 125% !important;
}
