
@import "colors";

.scoreHolder {
  display: flex;
  width: fit-content;
  margin: 0 auto;

  .scoreBox {
    font-size: 26px;
    font-weight: bold;
    padding: 0px 2px;
    p {
      padding: 0px 15px;
      color: $darkblue;
      background-color: white;
      font-weight: 800;
    }
    div {
      padding-bottom: 1px;
    }
  }
}