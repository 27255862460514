polygon,
polyline,
path,
text {
  pointer-events: all;
}

text:hover {
  cursor: pointer;
}

polygon:hover {
  opacity: 0.5;
  cursor: pointer;
}
polyline:hover {
  opacity: 0.5;
  cursor: pointer;
}

path:hover {
  opacity: 0.5;
  cursor: pointer;
}

.st0 {
  fill: #942364;
}
.st1 {
  fill: #962364;
}
.st2 {
  fill: #ccd63a;
}
.st3 {
  fill: #ced73a;
}
.st4 {
  fill: #054d77;
}
.st5 {
  fill: #064d77;
}
.st6 {
  fill: #80cfdb;
}
.st7 {
  fill: #80d1dd;
}
.st8 {
  fill: #4c2865;
}
.st9 {
  fill: #4d2865;
}
.st10 {
  fill: #f3b62d;
}
.st11 {
  fill: #f1b42d;
}
.st12 {
  fill: #d82a80;
}
.st13 {
  fill: #da2c82;
}
.st14 {
  fill: #9173aa;
}
.st15 {
  fill: #9373ab;
}
.st16 {
  fill: #0172ab;
}
.st17 {
  fill: #0172ac;
}
.st18 {
  fill: #ba6798;
}
.st19 {
  fill: #bc689a;
}
.st20 {
  fill: #88cbaf;
}
.st21 {
  fill: #8bcdb1;
}
.st22 {
  fill: #f3c875;
}
.st23 {
  fill: #f4ca75;
}
.st24 {
  fill: #f08cb7;
}
.st25 {
  fill: #f18eba;
}
.st26 {
  fill: #e2e193;
}
.st27 {
  fill: #e4e396;
}
.st28 {
  fill: #58595b;
}
.st29 {
  fill: #3f4444;
}
.st30 {
  font-family: 'ArialMT';
}
.st31 {
  font-size: 25px;
}
.st32 {
  fill: none;
  stroke: #3f4444;
  stroke-width: 2;
}
.st33 {
  fill: none;
  stroke: #333d3a;
  stroke-width: 2;
}

.test {
  fill: lightcyan;
}

.red {
  fill: #ff0000;
}
