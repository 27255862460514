.oneBg {
  min-height: 100vh;
  min-width: 100vw;
  background-image: url('../assets/home_bg.png');
  background-size: cover;

  .headerTitle {
    background-image: url('../assets/one_header_img.png');
    background-size: cover;

  }

}

.gameBg {
  color: white;
  // padding: 0px 200px;
  height: 100vh;
  width: 100vw;
  background-image: url('../assets/courtains_bg.png');
  background-size: cover;
}

.mainBg {
  color: white;
  min-height: 100vh;
  min-width: 950px;
  background: repeating-linear-gradient(
    -55deg,
    #11477a,
    #11477a 2px,
    #333 5px,
    #333 5px
  );
}


.curtain {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;

}

.curtainLeft {
  background-image: url('../assets/curtain_left.png');
  background-position: top 0 right 0;
}

.curtainRight {
  background-image: url('../assets/curtain_right.png');
  background-position: top 0 left 0;
}


@media screen and (max-width: 1450px) {

  .gameBg {
    color: white;
    min-height: 100vh;
    min-width: 100vw;
    background-image: unset;
    background: repeating-linear-gradient(
      -55deg,
      #11477a,
      #11477a 2px,
      #333 5px,
      #333 5px
    );
  }
}
