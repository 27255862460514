// @import "colors";

.questionWrapper{
  margin: 0 auto;
  max-width: 75%;
  height: 100vh;
  // border: 1px solid red;
  position: relative;

  .question {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 30px auto;
    min-height: 125px;
    font-size: 22px;

    .questionText {
      font-weight: 800;
    }

    .infoText {
      font-weight: 600;
    }
  }

  .answersWrapper {
    padding: 0 20px;
  }

  .references {
    font-size: 12px;
    position: absolute;
    bottom: 18px;
    // border: 1px solid yellow;
  }

}

