@import "colors";

.outerButton {
  position: relative;
  padding: 12px 16px;
  border-radius: 15px;
  background: rgb(252,252,252);
  background: linear-gradient(0deg, rgba(252,252,252,1) 43%, rgba(182,184,185,0.8477766106442577) 100%);
  cursor: pointer;

  .answerLetter {
    position: absolute;
    top: -12px;
    left: -12px;
    background: rgb(252,252,252);
    background: -moz-radial-gradient(circle, rgba(252,252,252,1) 28%, rgba(182,184,185,0.8477766106442577) 100%);
    background: -webkit-radial-gradient(circle, rgba(252,252,252,1) 28%, rgba(182,184,185,0.8477766106442577) 100%);
    background: radial-gradient(circle, rgba(252,252,252,1) 28%, rgba(182,184,185,0.8477766106442577) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcfcfc",endColorstr="#b6b8b9",GradientType=1);
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }

  .innerButton {
    padding: 0 5px;
    min-height: 4.5rem;
    border-radius: 10px;
    background: rgb(131,135,136);
    background: linear-gradient(0deg, rgba(131,135,136,0.8477766106442577) 0%, rgba(252,252,252,1) 55%);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    text-align: center;

    &.correct {
      background: $green !important;
    }

    &.wrong {
      background: $lightblue;
    }

    &.other {
      opacity: 0.9;
    }

  }

  span {
    font-size: 16px;
    font-weight: bold;
    color: $darkblue !important;
  }

  &:hover {
    transform: scale(1.01);
  }

}