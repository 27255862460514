:export {
  darkblue: #023967;
  midgray: #838788;
  midLightGray: #c5c3c1;
  darkgray: #4a4a4a;
  lightgray: #fcfcfc;
  otherGray: #3f4444;
  purple: #811953;
  yellow: #f0a529;
  green: #c3d532;
  white: #ffffff;
  red: #d24770;
  lightblue: #5ec2d2;
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: "Helvetica Neue", Helvetica;
}

html,
body {
  min-height: 100%;
  width: 100%;
  margin: auto;
  overflow: auto;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.flex .direction-column {
  flex-direction: column;
}

.flex-center {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.col {
  flex: 1;
  min-width: 50%;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

ol li {
  margin-bottom: 0.5rem;
}

ol li,
.modal-text {
  font-size: 1.2rem;
}

.modal-header-text {
  font-size: 1.5rem;
}

.game-sup {
  position: relative;
  top: -0.08em;
  vertical-align: top;
  font-size: 0.8rem;
}

.game-sup2 {
  position: relative;
  top: -0.08em;
  vertical-align: top;
  font-size: -0.2rem;
}

.center-text {
  text-align: center;
}

.bold {
  font-weight: bold !important;
}

.light {
  color: white;
}

.dark-gray-text {
  color: #4a4a4a;
}

.gray-text {
  color: #838788;
}

.purple {
  color: #811953;
}

.yellow {
  color: #f0a529;
}

.darkBlue {
  color: #023967;
  background-color: white;
}

.numericKeyBoardNV {
  background-color: transparent !important;
  color: #023967;
  display: flex;
  justify-content: center;
  font-size: 42px;
  font-weight: bold;
}
.numericKeyBoardNV .hg-rows {
  background-color: transparent !important;
}
.numericKeyBoardNV .hg-rows .hg-row {
  margin-bottom: 12px !important;
  width: 380px;
}
.numericKeyBoardNV .hg-rows .hg-row .hg-button {
  width: 60px !important;
  height: 90px !important;
  margin-right: 12px !important;
}
.numericKeyBoardNV .hg-rows .hg-row .nv-delete {
  font-size: 36px;
  width: 186px !important;
}
.numericKeyBoardNV .hg-rows .hg-row .nv-submit-lightblue {
  background-color: #5ec2d2 !important;
  font-size: 36px;
}

.numericKeyBoardNVDark {
  color: white;
}
.numericKeyBoardNVDark .hg-button {
  background-color: #023967 !important;
}
.numericKeyBoardNVDark .nv-submit-lightblue {
  color: #023967 !important;
}

.input-zipcode {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.input-zipcode div:before {
  border-bottom: none !important;
}

.select-interest-topic {
  font-size: 2rem !important;
}

.live-leaderboard-wrapper {
  width: 125% !important;
  max-width: 125% !important;
}

