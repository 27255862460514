@import "colors";

.leaderBoard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: $darkgray;
  // border: 1px solid red;

  .row {
    padding: 2px;
  }

  .column {
    text-align: center;
    padding: 8px;
    font-weight: 800;
    font-size: 1.2rem;
  }

  .outerColumn {
    background-color: #D8D9DA;
  }
  .nameColumn {
    background-color: #ebecec;
  }

  .outerColumnSelf {
    background-color: #84E4ED !important;
  }
  .nameColumnSelf {
    background-color: #E8FCFC !important;
  }

}
